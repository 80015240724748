<template>
	<div class="main">
		<div class="wrapper">   
			<!-- <div class="select-language-wrapper">  
				<v-select
				v-model="selectedLanguage"
				:items="dropdownLanguages"
				label="language"
				></v-select>
			</div> -->

			<div class="logo-wrapper">
				<v-img
					:src="require('../assets/logo_clock.svg')"
					contain
					width="100%"
				/>
			</div>

			<div class="form-wrapper">
				<v-form @submit.prevent>
					<v-text-field class="textfield"
					v-model="user"
					label="User"
					required
					prepend-inner-icon="mdi-account"
					@keyup.enter="validate"
					autocomplete="username"
					></v-text-field>

					<v-text-field
					v-model="password"
					:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
					:type="show ? 'text' : 'password'"
					label="Password"
					prepend-inner-icon="mdi-lock"
					@click:append="show = !show"
					@keyup.enter="validate"
					autocomplete="current-password"
					></v-text-field>

					<div v-if="!valid" class="warning-wrapper">
						<div class="error-message">
							<p class="error--text">{{errorMessage}}</p>
						</div>
						<div>
							<v-icon color="error">mdi-alert-circle</v-icon>
						</div>
					</div>

					<v-btn 
					:loading="loading" 
					type="submit" 
					class="primary login-btn" 
					@click="validate"
					>
					Sign in
					</v-btn><br>
					<router-link to="/reset-password" class="message--text">Forgot password?</router-link>
				</v-form>
			</div>

			<!-- <div class="logo-wrapper">
				<v-img
					:src="require('../assets/generic_logo.png')"
					contain
					width="100%"
				/>
			</div> -->
		</div>
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'Login',

	data: () => ({
		selectedLanguage: 'English',
		dropdownLanguages: ['Polski', 'English', 'Deutsch'],
		valid: true,
		loading: false,
		show: false,
		errorMessage: '',
		user: '',
		password: '',
	}),
	mounted(){
		this.$emit("set-state", "default");
		if (typeof(this.$route.query.username) !== 'undefined'){
			this.user = this.$route.query.username;
		}
	},
	methods: {
		validate(){
			if(this.user==''||this.password==''){
				this.valid=false
				this.errorMessage='Required user and password'
			}
			else{
				this.loading = true
				this.login()
			}
		},
		processPermissions(permissionData){
			var item;
			var response = [];

			for(let i = 0; i < permissionData.length; i++){
				item = permissionData[i];
				response.push(item.codename);
			}

			return response;
		},
		login(){
			// Clear the error upon uploading new data
			this.errorMessage = '';
			this.valid = true;

			axios({
				method:'post',
				url: appConfig.getApiUrl(null) + "/api/v1/login/",
				data: { 
					username: this.user,
					password: this.password
				},
			}).then((response) => {
				if(response.data.tenants.length != 1){
					if(response.data.tenants.length == 0){
						this.loading = false;
						this.valid=false;
						this.errorMessage='Invalid account configuration. Please contact the administrator.';
					}
					else{
						localStorage.jwt=response.data.access;
						localStorage.user=response.data.firstname+" "+response.data.lastname
						localStorage.usermail=response.data.email
						localStorage.username=response.data.username

						this.$router.push("/choose-tenant")
					}
					return;
				}

				localStorage.tenantName = response.data.tenants[0].name;
				localStorage.tenantSlug = response.data.tenants[0].slug;
				localStorage.jwt=response.data.access;
				localStorage.user=response.data.firstname+" "+response.data.lastname
				localStorage.usermail=response.data.email
				localStorage.username=response.data.username

				this.$emit('loggedIn', this.processPermissions(response.data.tenants[0].permissions), response.data.firstname+" "+response.data.lastname, response.data.email, response.data.tenants);

				// console.log("NOTICE: REMOVE THIS FROM CODE LATER!!!");
				// console.log("> Permissions list: ");
				// console.log(localStorage.permissions);
			})
			.catch((error) => {
				console.log(error)
				this.loading = false

				// If server is unreachable it won't return a response
				// This case has to be handled
				if (typeof(error.response) === 'undefined'){
					this.valid = false;
					this.errorMessage='Network error occured. Make sure you are connected to the internet'
					return;
				}

				switch(error.response.status){
					case 400:
						this.valid=false
						this.errorMessage='Bad request'
						break
					case 401: 
						this.valid=false
						this.errorMessage='Invalid user or password'
						break
					case 429:
						this.valid=false
						this.errorMessage='Too Many Requests'
						break;
					case 500:
						this.valid=false
						this.errorMessage='Server Error'
						break;
				}
			});
		},
	}
};
</script>

<style scoped>

.main{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
}


.wrapper{
	width: 30%;
	margin-left: 35%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	flex-wrap: wrap;
}

.select-language-wrapper{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100px;
}

.logo-wrapper{
	width: 80%;
	margin:20px 10%;
}

.form-wrapper{
	flex-basis: 100%;
	text-align: center;
}

.v-btn{
	color: #ffffff;
	width: 150px;
	padding: 2px;
	margin: 10px;
}

.login-btn:hover{
	box-shadow: 1px 1px 5px #6a7b8b;
}

.warning-wrapper{
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}

a {
	text-decoration: none;
	font-size: 14px;
}

@media only screen and (max-width: 1904px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 1264px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 960px) { 
	.wrapper {
		width: 50%;
		margin-left: 25%;
		transform: translate(0);
	}
}

@media only screen and (max-width: 600px) { 
	.wrapper {
		width: 70%;
		margin-left: 15%;
		transform: translate(0);
	}
}

</style>
